
// @ts-nocheck

import { Vue, Options } from 'vue-class-component'
import Datepicker from 'vue3-datepicker'
import swal from 'sweetalert'
import { userBillFactory } from '@/http/user-bill'
import * as bootstrap from 'bootstrap'
import constants from '@/constants/constants'
import Utils from '@/utils/utils'
import CurrencyInput from "./CurrencyInput.vue"
import CurrencyInputDisable from "./CurrencyInputDisable.vue"

@Options({
  components: {
    Datepicker,
    CurrencyInput,
    CurrencyInputDisable
  },
  data() {
    return {
      readMoreActivated: false
    }
  },
  props: {
    data: Object,
    math_operations: Array
  },
  methods: {
    formatDate: function(date:any) {
      var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()
      if (month.length < 2)
        month = '0' + month
      if (day.length < 2)
        day = '0' + day
      return [year, month, day].join('-')
    },
    addDetail: function() {
      this.data.details.push({
        id: "",
        name: "",
        operation: "+",
        default_amount: 0
      })
    },
    removeDetail: function(i:number) {
      this.data.details.splice(i, 1)
    },
    calculatePrice: function() {
      this.data.total_amount = 0
      this.data.details.forEach((bill:any) => {
        if (bill.bill_detail.operation == '-') {
          this.data.total_amount -= parseInt(bill.amount)
        } else {
          this.data.total_amount += parseInt(bill.amount)
        }
      })
    },
    beautifyDatetime: function(date:any) {
      return Utils.beautifyDatetime(date)
    },
    constants: function(status:any) {
      if (status > 0) {
        return constants[status]
      } else {
        return false
      }
    },
    save: function() {
      var details : any = []
      this.data.details.forEach((value:any) => {
        const detail = {
          id: value.id,
          amount: value.amount
        }
        details.push(detail)
      })
      var data = {
          active_date: this.data.active_date,
          deadline_date: this.formatDate(this.data.deadline_date),
          due_date: this.formatDate(this.data.due_date),
          details: details,
          is_published: (this.data.is_published) ? 1 : 0
      }
      Promise.resolve(userBillFactory.updateBillUser(this.data.spp_bill_id, this.data.id, data))
      .then(result => {
        if (result.error == false) {
          this.hideModal()
          // this.$emit('loadData')
          this.$parent.loadData('refresh')
          swal("Sukses menyimpan tagihan", "", "success")
        } else if (result.response.data.error == true) {
          var errorMessage = result.response.data.messages
          var msg = ''
          for (let i in errorMessage) {
              msg += errorMessage[i] + "<br>"
          }
          var length = 100
          msg = msg.substring(0, length)
          swal({
            text: "Gagal menyimpan tagihan siswa",
            icon: "error",
            content: {
              element: "p",
              attributes: {
                innerHTML: msg
              }
            }
          })
        }
      }).catch((e) => {
        swal("Gagal menyimpan tagihan", "", "error")
        console.log(e)
      })
    },
    showModal: function() {
      this.myModal.show()
    },
    hideModal: function() {
      this.myModal.hide()
    }
  },
  computed: {
    log1: function () {
      var log = this.data.log
      var log1 = log.slice(0,3)
      return log1
    },
    log2: function () {
      var log = this.data.log
      var log2 = log.slice(3)
      return log2
    },
    config() {
      return {
        currency: 'IDR',
        precision: 0,
        distractionFree: false,
        valueAsInteger: true,
      }
    }
  },
  async mounted () {
    var myModal = document.getElementById('billUserModal')
    if (myModal != null) {
      this.myModal = new bootstrap.Modal(myModal)
    }
  }
})

export default class EditUserBill extends Vue {}
